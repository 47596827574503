import React, { useState } from "react"
import { Link } from "gatsby"
import Logo from "./logo"
import ProgressBar from "./progressBar"

const Header = ({ navOverlay, progressEnabled, hiddenLogo }) => {

  const [toggleMenu, setToggleMenu] = useState(false);
  const menuToggle = () => {
    setToggleMenu(!toggleMenu);
  };

  const Header = ({ classes }) => {
    return (
      <React.Fragment>
        <header className={classes}>
          <div className="header-content">
            <div className="row no-gutters">
              <div className="col col-two-fifths">
                <div className="nav">
                  <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/for-lawyers/">For Lawyers</Link></li>
                    <li><Link to="/for-judges/">For Judges</Link></li>
                    <li><Link to="/the-project/">The Project</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col col-one-fifth">
                <div className="nav">
                  {hiddenLogo ? (
                    <span />
                  ) : (
                    <Logo />
                  )}
                </div>
              </div>
              <div className="col col-two-fifths">
                <div className="nav nav-right">
                  <ul>
                    <li><Link to="/research-team/">Research Team</Link></li>
                    <li><Link to="/news-&-events/">News &amp; Events</Link></li>
                    <li><Link to="/get-help/">Get Help</Link></li>
                    <li><Link to="/contact-us/">Contact Us</Link></li>
                  </ul>
                </div>
                <button className="hamburger-button" onClick={menuToggle} aria-label="Hamburger Button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <path fill="#000" d="M30,15H2c-0.6,0-1,0.4-1,1s0.4,1,1,1h28c0.6,0,1-0.4,1-1S30.6,15,30,15z"></path>
                    <path fill="#000" d="M30,6H2C1.4,6,1,6.4,1,7s0.4,1,1,1h28c0.6,0,1-0.4,1-1S30.6,6,30,6z"></path>
                    <path fill="#000" d="M30,24H2c-0.6,0-1,0.4-1,1s0.4,1,1,1h28c0.6,0,1-0.4,1-1S30.6,24,30,24z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className={toggleMenu ? 'mobile-nav open' : 'mobile-nav'}>
          <div className="mobile-nav-header">
            <Logo />
            <button className="hamburger-button" onClick={menuToggle} aria-label="Hamburger Button">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <line strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="#1F4D8F" strokeMiterlimit="10" x1="19" y1="5" x2="5" y2="19"></line>
                <line strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="#1F4D8F" strokeMiterlimit="10" x1="19" y1="19" x2="5" y2="5"></line>
              </svg>
            </button>
          </div>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/for-lawyers/">For Lawyers</Link></li>
            <li><Link to="/for-judges/">For Judges</Link></li>
            <li><Link to="/the-project/">The Project</Link></li>
            <li><Link to="/research-team/">Research Team</Link></li>
            <li><Link to="/news-&-events/">News &amp; Events</Link></li>
            <li><Link to="/get-help/">Get Help</Link></li>
            <li><Link to="/contact-us/">Contact Us</Link></li>
          </ul>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <ProgressBar enabled={progressEnabled} />
      <Header classes={navOverlay ? "header header-overlay" : "header"} />
    </React.Fragment>
  )
}

export default Header
