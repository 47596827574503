import React, { useState, useEffect } from "react"

const ProgressBar = ({ enabled }) => {

  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    let progressBarHandler = () => {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scroll = `${totalScroll / windowHeight * 100}`;
      setScroll(scroll);
    }
    window.addEventListener("scroll", progressBarHandler);
    return () => window.removeEventListener("scroll", progressBarHandler);
  });
  if (enabled) {
    return (
      <section className="progress-bar">
        <div style={{width: scroll + '%'}}></div>
      </section>
    )
  } else {
    return null
  }
}

export default ProgressBar
