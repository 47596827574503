import React from "react"
import { Link } from "gatsby"

const Logo = () => (
  <Link to="/" aria-label="Home">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" width="60" height="60">
      <g fill="#fff">
        <path d="M30 3.5c14.6 0 26.5 11.9 26.5 26.5S44.6 56.5 30 56.5 3.5 44.6 3.5 30 15.4 3.5 30 3.5m0-.3C15.2 3.2 3.2 15.2 3.2 30s12 26.8 26.8 26.8 26.8-12 26.8-26.8S44.8 3.2 30 3.2z" />
        <path d="M30 1.3c14.6 0 26.5 11.9 26.5 26.5S44.6 54.4 30 54.4 3.5 42.5 3.5 27.9 15.4 1.3 30 1.3m0-.2c-14.8 0-26.8 12-26.8 26.8s12 26.8 26.8 26.8 26.8-12 26.8-26.8S44.8 1.1 30 1.1z" />
        <path d="M33.2 1.3c14.6 0 26.5 11.9 26.5 26.5S47.8 54.4 33.2 54.4 6.7 42.5 6.7 27.9 18.6 1.3 33.2 1.3m0-.2c-14.8 0-26.8 12-26.8 26.8s12 26.8 26.8 26.8S60 42.7 60 27.9 48 1.1 33.2 1.1z" />
        <path d="M31.1 6.7c14.6 0 26.5 11.9 26.5 26.5S45.7 59.7 31.1 59.7 4.5 47.8 4.5 33.2 16.4 6.7 31.1 6.7m0-.3c-14.8 0-26.8 12-26.8 26.8S16.3 60 31.1 60s26.8-12 26.8-26.8-12-26.8-26.8-26.8z" />
        <path d="M26.8 4.5c14.6 0 26.5 11.9 26.5 26.5S41.4 57.6 26.8 57.6.2 45.7.2 31.1s12-26.6 26.6-26.6m0-.2C12 4.3 0 16.3 0 31.1s12 26.8 26.8 26.8 26.8-12 26.8-26.8-12-26.8-26.8-26.8z" />
        <path d="M27.9.2c14.6 0 26.5 11.9 26.5 26.5S42.5 53.3 27.9 53.3 1.3 41.4 1.3 26.8 13.2.2 27.9.2m0-.2C13.1 0 1.1 12 1.1 26.8s12 26.8 26.8 26.8 26.8-12 26.8-26.8S42.7 0 27.9 0z" />
        <path d="M32.1 3.5c14.6 0 26.5 11.9 26.5 26.5S46.8 56.5 32.1 56.5 5.6 44.6 5.6 30 17.5 3.5 32.1 3.5m0-.3C17.3 3.2 5.4 15.2 5.4 30s12 26.8 26.8 26.8S59 44.8 59 30 46.9 3.2 32.1 3.2z" />
      </g>
    </svg>
  </Link>
)

export default Logo
