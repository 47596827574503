import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "../styles/styles.scss"

const Layout = ({ classes, children, hiddenLogo, progressEnabled }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Header siteTitle={data.site.siteMetadata.title} navOverlay={true} hiddenLogo={hiddenLogo} progressEnabled={progressEnabled} />
      <div className={classes}>
        <div className="main">
          {/* <Cursor /> */}
          {children}
        </div>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
